import React from "react"

    export const ErrorPage = () => {
        return (
            <div>
                404 error page
            </div>
        )
    }

    export default ErrorPage;